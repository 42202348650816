import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import { Waypoint } from 'react-waypoint'
import Nav from '../components/Nav'
import ContactForm from '../components/ContactForm'

class Kontakt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <Layout>
        <Helmet title="Crabber - Webservice | Kontakt" />
                <HeaderGeneric title="Crabber - Webservice" />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} active="kontakt" />

        <Nav page="generic" />
        <div id="main">
          <section id="contact" className="main contact">

            <header className="major">
              <h2>Kontakt</h2>
            </header>

            <p>
              Für Fragen, Anregungen und sonstiges nutze bitte das Kontaktformular und schreibe uns eine Nachricht. Wir melden uns umgehend zurück.
            </p>

            <ContactForm />

          </section>
        </div>
      </Layout>
    )
  }
}

export default Kontakt
